<script>
	export var total = 0

	function addNumbers(){
		total = 0
		var numbers = document.getElementById("numbers").value.split("")
		numbers.map(n => total += Number(n))
		console.log("Adding numbers")
	}
</script>


	<input id="numbers" type="text" value="" on:input={addNumbers}>
	<p>{total}</p>

<style>
	main {
		text-align: center;
		padding: 1em;
		max-width: 240px;
		margin: 0 auto;
	}

	h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>